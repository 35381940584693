/*
 * @Descripttion : 用户信息
 * @version      :
 * @Author       : ml
 * @Date         : 2020-08-27 17:23:52
 * @LastEditors  : min
 * @LastEditTime : 2020-12-08 10:11:28
 */

// store.state.userStore.permissions

const state = {
	id: '',
	nickname: '',
	username: '',
	phone: '',
	telephone: '',
	email: '',
	birthday: '',
	sex: '',
	status: '',
	permissions: null, //  用户权限
	menuList: null, //  菜单权限
	type: "",	//	用户类型
}
const mutations = {
	SET_USER_INFO(state, userInfo) {
		if (userInfo) {
			state.id = userInfo.id
			state.nickname = userInfo.nickname
			state.username = userInfo.username
			state.phone = userInfo.phone
			state.telephone = userInfo.telephone
			state.email = userInfo.email
			state.birthday = userInfo.birthday
			state.sex = userInfo.sex
			state.status = userInfo.status
			state.type = userInfo.type
		} else {
			state.id = ''
			state.nickname = ''
			state.username = ''
			state.phone = ''
			state.telephone = ''
			state.email = ''
			state.birthday = ''
			state.sex = ''
			state.status = ''
			state.type = ''
		}
	},
	EDIT_USER_INFO(state, userInfo) {
		if (userInfo) {
			state.nickname = userInfo.nickname
			state.username = userInfo.username
			state.phone = userInfo.phone
			state.telephone = userInfo.telephone
			state.email = userInfo.email
			state.birthday = userInfo.birthday
			state.sex = userInfo.sex
			state.type = userInfo.type
		} else {
			state.nickname = ''
			state.username = ''
			state.phone = ''
			state.telephone = ''
			state.email = ''
			state.birthday = ''
			state.sex = ''
			state.type = ''
		}
	},
	SET_USER_PERMISSION(state, permissions) {
		state.permissions = permissions
	},
	SET_USER_MENU(state, menuList) {
		state.menuList = menuList
	},
}
const actions = {
	setUserInfo({ commit }, userInfo) {
		commit('SET_USER_INFO', userInfo)
	},
	editUserInfo({ commit }, userInfo) {
		commit('EDIT_USER_INFO', userInfo)
	},
	setUserPermission({ commit }, permissions) {
		commit('SET_USER_PERMISSION', permissions)
	},
	setUserMenu({ commit }, menuList) {
		commit('SET_USER_MENU', menuList)
	},
}
const getters = {}

// 不要忘记把state, mutations等暴露出去。
export default {
	state,
	mutations,
	actions,
	getters,
}
