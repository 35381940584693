/*
 * @Descripttion : 用户信息
 * @version      :
 * @Author       : ml
 * @Date         : 2020-08-27 15:55:08
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-01 13:37:50
 */
import request from './request'

// 登录接口
export function login(data) {
	return request.post('login', data)
}

// 获取用户信息
export function getUserInfo(data) {
	return request.fetch('users/current', data)
}

// 获取菜单
export function getPermissions() {
	return request.fetch('permissions/current')
}

// 修改密码
export function editPwd(username, data) {
	return request.put('users/' + username, data)
}

// 修改登录用户信息
export function editUserInfo(data) {
	return request.put('users', data)
}

// 获取用户列表
export function getUserList(data) {
	return request.fetch('users/', data)
}

// 修改用户信息
export function updateUserInfo(data) {
	return request.put('users', data)
}

// 添加用户信息
export function AddUserInfo(data) {
	return request.post('users', data)
}

// 获取用户信息
export function getUserInfoByID(id, data) {
	return request.fetch('users/' + id, data)
}

// 重置密码
export function resetPassword(id) {
	return request.put("/users/" + id + "/resetPassword");
  }
  