/*
 * @Descripttion :
 * @version      :
 * @Author       : ml
 * @Date         : 2020-09-03 14:59:39
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-10 09:48:50
 */
const rotuerCofig = [
	{
		path: '/menuList',
		name: 'menuList',
		meta: {
			title: '菜单',
			auth: true,
		},
		component: () => import('@/views/systemMangae/menuManage.vue'),
	},
	{
		path: '/roleList',
		name: 'roleList',
		meta: {
			title: '角色',
			auth: true,
		},
		component: () => import('@/views/systemMangae/roleManage.vue'),
	},
	{
		path: '/appSecretList',
		name: 'appSecretList',
		meta: {
			title: '密钥管理',
			auth: true,
		},
		component: () => import('@/views/systemMangae/appSecretList.vue'),
	},
	{
		path: '/supplierList',
		name: 'supplierList',
		meta: {
			title: '供应商管理',
			auth: true,
		},
		component: () => import('@/views/supplierManage/supplierList.vue'),
	},
	{
		path: '/supplierHeadList',
		name: 'supplierHeadList',
		meta: {
			title: '供应商总部',
			auth: true,
		},
		component: () => import('@/views/supplierManage/supplierHeadList.vue'),
	},
	{
		path: '/supplierUserList',
		name: 'supplierUserList',
		meta: {
			title: '供应商用户',
			auth: true,
		},
		component: () => import('@/views/supplierManage/supplierUserList.vue'),
	},
	{
		path: '/userList',
		name: 'userList',
		meta: {
			title: '用户查询',
			auth: true,
		},
		component: () => import('@/views/userManage/userList.vue'),
	},
	{
		path: '/logList',
		name: 'logList',
		meta: {
			title: '日志查询',
			auth: true,
		},
		component: () => import('@/views/systemMangae/logList.vue'),
	},
	// {
	// 	path: '/dictList',
	// 	name: 'dictList',
	// 	meta: {
	// 		title: '字典管理',
	// 		auth: true,
	// 	},
	// 	component: () => import('@/views/systemMangae/dictList.vue'),
	// },
	{
		path: '/certificationOrder',
		name: 'certificationOrder',
		meta: {
			title: '办证订单',
			auth: true,
		},
		component: () => import('@/views/orderManage/certificationOrder.vue'),
	},
]

export default rotuerCofig
