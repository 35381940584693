<!--
 * @Descripttion : 登录页面
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-08-27 14:12:42
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-22 10:25:36
-->
<template>
  <div class="ml_login">
    <div class="ml_login_card">
      <div class="ml_login_card_left">
        <img src="../assets/login-3.png" class="ml_login_card_left_logo" alt="">
        <img src="../assets/login-1.jpg" class="ml_login_card_left_img" alt="">
      </div>
      <div class="ml_login_card_right">
        <div class="ml_login_content">
          <div class="ml_login_header">
            <h1>办证系统</h1>
          </div>
          <div class="ml_login_form">
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
              <a-form-model-item prop="username">
                <a-input v-model="form.username" placeholder="用户名" @pressEnter="handleSubmit">
                  <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item prop="password">
                <a-input-password v-model="form.password" type="password" placeholder="密码" @pressEnter="handleSubmit">
                  <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
                </a-input-password>
              </a-form-model-item>
              <a-form-model-item>
                <a-button type="primary" @click="handleSubmit" block>
                  登录
                </a-button>
              </a-form-model-item>
            </a-form-model>

          </div>
          <div class="ml_copyright">
            ©{{new Date().getFullYear()}} Created by CarWins
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { login } from "@/api/users";
import { loadRouter } from "../config/userConfig";
export default {
  data() {
    return {
      rules: {
        username: [{ required: true, message: "请输入用户名!" }],
        password: [{ required: true, message: "请输入密码!" }]
      },
      form: {
        username: "",
        password: ""
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.ruleForm.resetFields();
    });
  },
  methods: {
    handleSubmit() {
      const that = this;
      that.$refs.ruleForm.validate(valid => {
        if (valid) {
          login(that.form).then(res => {
            localStorage.setItem("cbz_token", res.token);
            loadRouter(true);
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" >
.ml_login {
  height: 100vh;
  width: 100vw;
  background: url(../assets/login-2.jpg) no-repeat center center;
  background-size: 100% 100%;

  //   background-image: -webkit-radial-gradient(
  //     ellipse,
  //     rgba(114, 146, 171, 0.5),
  //     rgba(68, 98, 126, 0.9)
  //   );
  //   background-image: radial-gradient(
  //     ellipse,
  //     rgba(114, 146, 171, 0.5),
  //     rgba(68, 98, 126, 0.9)
  //   );
  position: relative;

  .ml_login_card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 400px;
    display: flex;
    .ml_login_card_left {
      flex: 250px;
      position: relative;
      .ml_login_card_left_img {
        height: 100%;
        width: 100%;
      }
      .ml_login_card_left_logo {
        position: absolute;
        top: 70px;
        left: 20%;
        width: 60%;
      }
    }
    .ml_login_card_right {
      flex: 350px;
      .ml_login_content {
        background-color: #fff;

        .ml_login_header {
          text-align: center;
          font-size: 18px;
          padding: 20px 0 0 0;
          h1 {
            color: #5471cc;
          }
        }

        .ml_login_form {
          width: 350px;
          height: 270px;
          background-color: #fff;
          border-radius: 5px;
          padding: 20px 30px;
          box-shadow: 0px 0px 60px 10px rgba(255, 255, 255, 0.3);
          .ant-form-item {
            margin: 0 0 10px 0;
            .ant-form-item-control {
              height: 55px;
            }
          }
        }
        .ml_copyright {
          font-size: 12px;
          color: #797979;
          text-align: center;
          padding: 10px 0;
        }
      }
    }
  }
}
</style>