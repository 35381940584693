/*
 * @Descripttion : 公共路由
 * @version      :
 * @Author       : ml
 * @Date         : 2020-09-08 10:25:47
 * @LastEditors: min
 * @LastEditTime: 2020-10-30 19:51:32
 */
const rotuerPublic = [
	{
		path: '/orderDetail',
		name: 'orderDetail',
		meta: {
			title: '订单详情', //  处理  审核
			auth: true,
			keepAlive: true,
		},
		component: () => import('@/views/orderManage/orderDetail.vue'),
	},
	{
		path: '/orderDetailView',
		name: 'orderDetailView',
		meta: {
			title: '订单详情',
			auth: true,
			keepAlive: true,
		},
		component: () => import('@/views/orderManage/orderDetailView.vue'),
	},
]

export default rotuerPublic
