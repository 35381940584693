/*
 * @Descripttion : 系统设置
 * @version      :
 * @Author       : ml
 * @Date         : 2020-08-28 20:21:15
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-09 18:28:25
 */
import request from './request'

/**
 * 菜单权限管理
 */

// 菜单信息所有信息
export function getPermissionsList(data) {
	return request.fetch('permissions', data)
}

// 删除菜单
export function delPermission(id) {
	return request.delete('permissions/' + id)
}

// 获取上级菜单
export function getPrarntPermission(data) {
	return request.fetch('permissions/parents', data)
}

// 获取菜单详情
export function getPermission(id, data) {
	return request.fetch('permissions/' + id, data)
}

// 更新菜单详情
export function updatePermission(data) {
	return request.put('permissions/', data)
}

// 新增菜单详情
export function addPermission(data) {
	return request.post('permissions', data)
}

// 获取获取所有菜单
export function getPermissionAll(data) {
	return request.fetch('permissions/all', data)
}

// 根据角色ID获取权限
export function getPermissionByRoleID(data) {
	return request.fetch('permissions', data)
}

// 获取用户所有权限
export function getPermissionOwns(data) {
	return request.fetch('permissions/owns', data)
}

/**
 *  角色管理
 */

// 获取角色列表
export function getRoleList(data) {
	return request.fetch('roles', data)
}

// 新增角色
export function addRole(data) {
	return request.post('roles', data)
}

// 更新角色
export function updateRole(data) {
	return request.post('roles', data)
}

// 获取角色详情
export function getRole(id, data) {
	return request.fetch('roles/' + id, data)
}

// 获取所有角色
export function getRoleAll(data) {
	return request.fetch('roles/all', data)
}

// 根据UserID获取角色
export function getRoleByUserID(data) {
	return request.fetch('roles', data)
}

// 删除角色
export function delRole(id) {
	return request.delete('roles/' + id)
}

/**
 * 日志管理
 */

// 获取日志列表
export function getLogList(data) {
	return request.fetch('logs', data)
}

// 获取字典列表
export function getDictsList(data) {
	return request.fetch('dicts', data)
}

/**
 * 密钥管理
 */

// 密钥实列分页
export function getAppSecretList(data) {
	return request.fetch('appSecret', data)
}

// 编辑 密钥
export function updateAppSecret(data) {
	return request.put('appSecret', data)
}

// 根据id获取密钥
export function getAppSecret(id) {
	return request.fetch('appSecret/' + id)
}

// 删除 密钥 实例
export function delAppSecret(id) {
	return request.delete('appSecret/' + id)
}

// 新增 密钥 实例
export function addAppSecret(data) {
	return request.post('appSecret/add', data)
}
