/*
 * @Descripttion : 登录后用户配置
 * @version      :
 * @Author       : ml
 * @Date         : 2020-09-03 15:48:21
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-10 19:46:34
 */

import store from '../store'
import { getUserInfo, getPermissions } from '../api/users'
import { getPermissionOwns } from '../api/system'

import routerConfig from '../router/modules/router'
import rotuerPublic from '../router/modules/routerPublic'

import router from '../router'

let userMenu = []
// 加载权限菜单
function loadMenu(name) {
	routerConfig.forEach((element) => {
		if (element.name === name) {
			userMenu.push(element)
		}
	})
}

export function loadRouter(isLogin) {
	// 获取用户所有权限
	getPermissionOwns({
		_: new Date(),
	}).then((res) => {
		store.dispatch('setUserPermission', res.data)

		// 加载公共菜单
		rotuerPublic.forEach((element) => {
			if (
				element.name == 'orderDetail' &&
				(res.data.indexOf('certificationOrder:audit') > -1 ||
					res.data.indexOf('certificationOrder:upload') > -1)
			) {
				// 审核、处理
				userMenu.push(element)
			}

			if (
				element.name == 'orderDetailView' &&
				res.data.indexOf('certificationOrder:query') > -1
			) {
				// 查看详情
				userMenu.push(element)
			}
		})

		// 获取菜单权限
		getPermissions().then((res) => {
			let _list = []
			let defaultRoute = ''
			res.data.forEach((element, index) => {
				if (element.type === 1) {
					loadMenu(element.href)
					let _children = []
					if (element.child.length > 0) {
						//   子菜单
						element.child.forEach((item) => {
							_children.push({
								key: item.id,
								title: item.name,
								path: item.href,
								icon: item.css,
							})
							loadMenu(item.href)
						})

						_list.push({
							key: element.id,
							title: element.name,
							path: '',
							icon: element.css,
							children: _children,
						})

						if (index == 0) {
							// 二级菜单
							defaultRoute = _list[0].children[0].path
						}
					} else {
						//   无子菜单
						if (element.type === 1) {
							_list.push({
								key: element.id,
								title: element.name,
								icon: element.css,
								path: element.href,
							})
							if (index == 0) {
								// 一级菜单
								defaultRoute = _list[0].path
							}
						}
					}
				}
			})
			store.dispatch('setUserMenu', _list)

			let getRouter = [
				{
					path: '/index',
					name: 'index',
					component: () => import('@/views/LayOut.vue'),
					redirect: defaultRoute,
					children: userMenu,
				},
			]

			router.options.routes = getRouter
			router.addRoutes(getRouter)

			if (isLogin) {
				// 登录后跳转到主页
				if (defaultRoute) {
					router.push(defaultRoute)
				}
			}
		})
	})

	// 用户信息
	getUserInfo({
		_: new Date().getTime(),
	}).then((res) => {
		store.dispatch('setUserInfo', res.data)
	})
}
