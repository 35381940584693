/*
 * @Descripttion : store 模块
 * @version      :
 * @Author       : ml
 * @Date         : 2020-08-27 13:43:26
 * @LastEditors  : ml
 * @LastEditTime : 2020-08-28 14:39:10
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import userStore from './modules/userStore'

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		userStore,
	},
})
